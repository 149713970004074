import { Box } from '@mui/material';
import { FreightExtraGlSap } from '../types';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';
import { FileReaderColumnProps } from '../components/FileReader';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const FreightExtraGlSapEntity: WebEntity<FreightExtraGlSap> = {
  name: 'freightExtraGlSap',
  endpoint: 'freightExtra/glSap',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text',
      size: 200
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Mes',
      accessorKey: 'freightExtraGlSapMonth',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) =>
        row.original?.freightExtraGlSapMonth
          ? `${
              spanishMonthNames[new Date(row.original?.freightExtraGlSapMonth).getUTCMonth()]
            } ${new Date(row.original?.freightExtraGlSapMonth).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Fecha',
      accessorKey: 'freightExtraGlSapDate',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original?.freightExtraGlSapDate, 'DD/MM/YYYY', true)
    },
    {
      header: 'Monto',
      accessorKey: 'amount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.amount ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row?.original?.amount, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.amount ? acc + Number(obj.amount) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Centro Costo SAP',
      accessorKey: 'costCenterSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Cuenta SAP',
      accessorKey: 'accountSap',
      columnType: 'text',
      size: 200
    }
  ]
};

export const FREIGHT_EXTRA_GL_SAP_COLUMNS: FileReaderColumnProps = {
  accountingDate: 'Fe.contabilización',
  accountSap: 'Cuenta de mayor',
  amount: 'Valor de moneda de sociedad',
  carrierRut: { name: 'Conc.búsq.', required: false, upload: true, export: true },
  bp: { name: 'Proveedor', required: false, upload: true, export: true },
  costCenterSap: 'Centro de coste'
};

export default FreightExtraGlSapEntity;
