import { RefusedEstimatedClose } from '../types';
import { formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import { EditRefusedEstimatedClosed } from '../components/SecondaryButton';

const EstimatedClosingRejectedEntity: WebEntity<RefusedEstimatedClose> = {
  name: 'expenses',
  endpoint: 'expenses/closingEstimateRejected',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: 'options',
      columnType: 'text',
      omitExport: true,
      size: 160,
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: 'flex',
              gap: 20
            }}>
            {EditRefusedEstimatedClosed(row?.original)}
          </div>
        );
      },
      enableEditing: false
    },
    {
      header: 'ID',
      accessorKey: '_id',
      columnType: 'text'
    },
    {
      header: 'Comentario',
      accessorKey: 'comment',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Texto Explicativo',
      accessorKey: 'estimatedDescription',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Comentario Justificacion',
      accessorKey: 'justificationCommentary',
      id: 'justificationCommentary',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Cta',
      accessorKey: 'accountSap',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Cta PS',
      accessorKey: 'ctaPs',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Descripcion Categoria',
      accessorKey: 'accountDescription',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Centro de costo',
      accessorKey: 'costCenterName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Estimado Cierre',
      accessorKey: 'estimatedTotal',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row?.original?.estimatedTotal)
    }
  ],

  editableFields: [
    {
      name: 'Total',
      selector: 'estimatedTotal',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Cuenta',
      selector: 'accountDescription',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'Cuenta',
        endpoint: 'dictionaryCc',
        select: 'accountDescription',
        show: 'accountDescription'
      }
    },
    {
      name: 'Centro de costo',
      selector: 'costCenterName',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'costCenter',
        endpoint: 'costCenter/user',
        select: 'costCenterName',
        show: 'costCenterName'
      }
    }
  ]
};

export const PENDINGS_ESTIMATED_CLOSED_COLUMNS = {
  categorieName: 'Categoria',
  accountSap: 'Cta',
  ctaPs: 'Cta PS',
  accountDescription: 'Descipcion Cuenta',
  estimatedTotal: 'Estimado Cierre'
};

export default EstimatedClosingRejectedEntity;
