import { Box } from '@mui/material';
import { CarriageResume } from '../types';
import { WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const percentageFormat = (v: string) => `${parseFloat((Number(v) * 100).toFixed(2).toString())}%`;

const CarriageResumeEntity: WebEntity<CarriageResume> = {
  name: 'carriageResume',
  endpoint: 'carriageResume',
  referenceColumn: '´name',
  tableColumns: [
    {
      header: 'Grupo',
      accessorKey: 'group',
      columnType: 'text',
      size: 150,
      Cell: ({ row }) => <Box sx={{ fontWeight: 'bold' }}>{row?.original?.group}</Box>
    },
    {
      header: 'Nombre',
      accessorKey: 'name',
      columnType: 'text',
      size: 230,
      Cell: ({ row }) => <Box sx={{ fontWeight: 'bold' }}>{row?.original?.name}</Box>
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;

        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.january, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.january)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.january)) && Number(row?.original?.january) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.january)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.february, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.february)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.february)) && Number(row?.original?.february) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.february)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.march, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.march)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.march)) && Number(row?.original?.march) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.march)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.april, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.april)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.april)) && Number(row?.original?.april) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.april)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.may, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.may)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.may)) && Number(row?.original?.may) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.may)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.june, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.june)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.june)) && Number(row?.original?.june) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.june)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.july, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.july)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.july)) && Number(row?.original?.july) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.july)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.august, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.august)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.august)) && Number(row?.original?.august) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.august)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.september, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.september)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.september)) && Number(row?.original?.september) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.september)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.october, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.october)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.october)) && Number(row?.original?.october) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.october)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.november, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.november)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.november)) && Number(row?.original?.november) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.november)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.december, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.december)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.december)) && Number(row?.original?.december) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.december)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) => {
        const type = row?.original?.type;
        const value =
          type === 'numeric'
            ? formatStringNumber(row?.original?.total, 0, 2)
            : type === 'percentage'
            ? percentageFormat(row?.original?.total)
            : undefined;

        const color =
          value && !isNaN(Number(row?.original?.total)) && Number(row?.original?.total) < 0
            ? '#FF0000'
            : '#000';

        return !isNaN(Number(row?.original?.total)) ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color
            }}>
            {value}
          </Box>
        ) : (
          ''
        );
      },
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (cell?.row?.groupingValue as string).includes('RESUMEN') ? (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        ) : null;
      }
    }
  ]
};

export default CarriageResumeEntity;
