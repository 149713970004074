import { CSSProperties, Fragment, ReactNode, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from 'reactstrap';
import { ColumnType, WebEntity, WebEntityName } from '../../entities/types';
import { AuthState, EntitiesState, ErrorState } from '../../reducers/types';
import { BasicEntity, UserTypeValues } from '../../types';
import { capitalize } from '../../utils/utils';
import { useUserAccess } from '../hooks';
import { ToastContainer, toast } from 'react-toastify';
import { MRT_RowData, MRT_TableInstance } from 'material-react-table';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  AdditionalTopTableComponents,
  BodyRowProps,
  ColumnComponent,
  CRUDAllowedActions,
  CustomTopTable,
  InitialState
} from '../types';
import GenericModalForm from './GenericModalForm';
import GenericModalMultiInputForm from './GenericModalMultiInputForm';
import GenericTable from './GenericTable';
import Swal from 'sweetalert2';
import { Button, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

type Props<T> = {
  // Global States
  entities: EntitiesState;
  error: ErrorState;
  auth: AuthState;

  // Redux Actions
  getEntities: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  selectedEntities: (selected: T[]) => any;
  singleSelectedEntity: (selected: T) => any;
  deleteEntity: (id: string[]) => any;
  addEntity: (entity: T | T[]) => any;
  multiAddEntity: (entity: T | T[]) => any;
  editEntity: (entity: T) => any;
  notifyMessageEntity: (msg: string) => void;

  // Aditional
  webEntity: WebEntity<T>;
  title: string;
  signalsToWatch: string[];
  allowedActions?: Partial<CRUDAllowedActions<T>>;
  serverSidePagination?: boolean;
  enablePagination?: boolean;
  additionalTableProps?: AdditionalTableProps<T>;
  additionalTableActions?: AdditionalTableActionsFunc<T>;
  addMultiLineAction?: JSX.Element;
  expandableRowsComponent?: () => ReactNode;
  editAction?: (row: BasicEntity) => JSX.Element;
  columnComponent?: ColumnComponent<T>;
  preProcessEntityList?: (entityList: Array<any>) => Array<any>;
  sharedFilterName?: string;
  customExport?: {
    headerStyle: {
      backgroundColor: string;
      color: string;
    };
  };
  liveFilter?: boolean;

  // Styles General
  headerScroll?: boolean;
  headerScrollHeight?: string;
  viewInputFilter?: boolean;
  initialState?: InitialState;
  enableColumnVirtualization?: boolean;
  enableRowVirtualization?: boolean;
  optionsPagination?: {
    initialStatePagination?: { pagination: { pageSize: number; pageIndex: number } };
  };
  enableCountError?: boolean;
  columnsHeaderStyle?: CSSProperties;
  style?: CSSProperties;
  additionalTopTableActions?: AdditionalTopTableComponents[];
  customTopTable?: CustomTopTable;
  enableStickyFooter?: boolean;
  bodyRowProps?: BodyRowProps;
};

const GenericCRUD = <T,>({
  entities,
  error,
  auth,
  getEntities,
  selectedEntities,
  singleSelectedEntity,
  deleteEntity,
  addEntity,
  multiAddEntity,
  editEntity,
  notifyMessageEntity,
  webEntity,
  title,
  signalsToWatch,
  allowedActions,
  serverSidePagination,
  additionalTableProps,
  enablePagination,
  additionalTableActions,
  expandableRowsComponent,
  editAction,
  columnComponent,
  preProcessEntityList,
  sharedFilterName,
  customExport,
  liveFilter,
  enableCountError,
  columnsHeaderStyle,
  style,
  headerScroll,
  headerScrollHeight,
  viewInputFilter,
  initialState,
  enableColumnVirtualization,
  enableRowVirtualization,
  optionsPagination,
  additionalTopTableActions,
  customTopTable,
  enableStickyFooter,
  bodyRowProps
}: Props<T>) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const {
    list: entityList,
    loading,
    notificationMessage
  } = entities[webEntity.name as WebEntityName];

  useUserAccess(auth);

  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setErrorMsg(error.msg.msg);
      setTimeout(() => setErrorMsg(''), 5000);
    }

    if (notificationMessage) {
      setTimeout(() => notifyMessageEntity(''), 5000);
    }
  }, [error, notificationMessage, notifyMessageEntity]);

  const { isAuthenticated, user } = auth;
  const isAdmin = user?.userTypeName.includes(UserTypeValues.admin) || false;

  const onAddEntity = () => async (fields: T | T[]) => {
    toast.promise(
      async () => {
        const res = await addEntity(fields);
        if (res && res.status === 200) {
          if (res.data && res.data.length > 0 && res.data[0].successMessage) {
            // TODO: Make the success message not to be in the data array
            if (
              res.data[0].successMessage &&
              res.data[0].successMessage.icon &&
              res.data[0].successMessage.title &&
              res.data[0].successMessage.html
            ) {
              Swal.fire({
                icon: res.data[0].successMessage.icon,
                title: res.data[0].successMessage.title,
                html: res.data[0].successMessage.html
              });
            }
          }
          return true;
        } else {
          throw error;
        }
      },
      {
        pending: 'Cargando...',
        success: `Se insertó correctamente el registro`,
        error: 'Error, no se pudo insertar el registro'
      }
    );
  };

  const onMultiAddEntity = () => async (fields: T | T[]) => {
    toast.promise(
      async () => {
        const res = await multiAddEntity(fields);
        if (res && res.status === 200) {
          return res;
        } else {
          throw error;
        }
      },
      {
        pending: 'Cargando...',
        success: `Se insertaron correctamente los registros`,
        error: 'Error, no se pudieron insertar los registros'
      }
    );
  };

  const onDeleteEntity =
    (
      getSelectedData: () => { ids: string[]; refs: string },
      table: MRT_TableInstance<MRT_RowData>
    ) =>
    async () => {
      const { ids, refs } = getSelectedData();

      Swal.fire({
        icon: 'question',
        title: refs,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then(async (result) => {
        if (result.isConfirmed) {
          toast.promise(
            async () => {
              const res = await deleteEntity(ids);
              table.resetRowSelection();
              if (res) {
                //notifyMessageEntity(`Se han elimando correctamente los siguientes datos: ${res}`);
                if (serverSidePagination) {
                  setForceRefresh(!forceRefresh);
                }
                return res;
              } else {
                //notifyMessageEntity(`La accion no se ha completado correctamente`);
                throw error;
              }
            },
            {
              pending: 'Cargando...',
              success: {
                render({ data }) {
                  return `Se elimino correctamente el registro: ${data}`;
                }
              },
              error: 'Error, no se puede eliminar el registro'
            }
          );
        }
      });
    };

  const onEditEntity = (row: BasicEntity) => async (updatedEntity: T) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro que deseas continuar con la edición?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.promise(
          async () => {
            const res = await editEntity({ _id: row._id, ...updatedEntity });
            if (res) {
              // TODO: Make the success message not to be in the data array
              if (
                res.successMessage &&
                res.successMessage.icon &&
                res.successMessage.title &&
                res.successMessage.html
              ) {
                Swal.fire({
                  icon: res.successMessage.icon,
                  title: res.successMessage.title,
                  html: res.successMessage.html
                });
              }
              return res;
            } else {
              throw error;
            }
          },
          {
            pending: 'Cargando...',
            success: {
              render({ data }) {
                const referenceColumn = data.hasOwnProperty(webEntity?.referenceColumn)
                  ? data[webEntity.referenceColumn]
                  : row._id;
                return `Se editó correctamente el registro: ${referenceColumn}`;
              }
            },
            error: 'Error, no se pudo editar el registro'
          }
        );
      }
    });
  };

  const renderAddButton = (): JSX.Element => (
    <GenericModalForm
      webEntity={webEntity}
      actionFn={onAddEntity()}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
      buttonName={webEntity.customButtonNames?.addButtonName || ''}
      title={webEntity.customDataActions?.addModalTitle}
    />
  );

  const renderAdditionalTopTableActions = (): JSX.Element => (
    <Fragment>
      {additionalTopTableActions?.map((a, idx) => (
        <Fragment key={idx}>{a.component}</Fragment>
      ))}
    </Fragment>
  );

  const renderAddMultiLineButton = (): JSX.Element => (
    <GenericModalMultiInputForm
      webEntity={webEntity}
      actionFn={onMultiAddEntity()}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
    />
  );

  const selectedSingleEntity = (row: T, _: Event) => {
    singleSelectedEntity(row);
  };

  const renderEditButton = (row: BasicEntity): JSX.Element => (
    <GenericModalForm
      webEntity={webEntity}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
      actionFn={onEditEntity(row)}
      alternativeButton={(toggle: () => void) => (
        <IconButton onClick={toggle}>
          <EditIcon />
        </IconButton>
      )}
      entityToEdit={row}
      title={webEntity.customDataActions?.editModalTitle || `Editar ${webEntity.name}`}
      actionName={'Editar'}
    />
  );

  const renderDeleteButton = (
    getSelectedData: () => { ids: string[]; refs: string },
    table: MRT_TableInstance<MRT_RowData>
  ): JSX.Element => (
    <Button onClick={onDeleteEntity(getSelectedData, table)} variant='contained' color='error'>
      <DeleteIcon />
      {webEntity.customButtonNames?.deleteButtonName || 'Eliminar'}
    </Button>
  );

  const preProcessedEntityList = preProcessEntityList
    ? preProcessEntityList(entityList)
    : entityList;

  const allowedActionSelect = {
    ...{
      enable: false,
      single: false,
      pageOnly: false,
      rowDisabledCriteria: undefined
    },
    ...allowedActions?.select
  };

  const allowedTableActions = {
    add: isAuthenticated && allowedActions?.add,
    multiLineForm: isAuthenticated && allowedActions?.multiLineForm,
    delete: isAuthenticated && allowedActions?.delete,
    edit: isAuthenticated && allowedActions?.edit,
    editCondition: allowedActions?.editCondition,
    export: isAuthenticated && allowedActions?.export,
    select: isAuthenticated && allowedActionSelect.enable,
    singleSelect: isAuthenticated && allowedActionSelect.single
  };

  const localColumnComponent =
    columnComponent !== undefined && allowedTableActions.edit
      ? ({
          ...columnComponent,
          column: {
            ...columnComponent.column,
            cell: (row: T) => (
              <>
                {renderEditButton(row as unknown as BasicEntity)}
                {columnComponent.column.cell(row)}
              </>
            )
          }
        } as unknown as ColumnComponent<T>)
      : !columnComponent && allowedTableActions.edit
      ? ({
          begin: false,
          column: {
            cell: renderEditButton,
            allowOverflow: true,
            button: true
          }
        } as unknown as ColumnComponent<T>)
      : columnComponent && !allowedTableActions.edit
      ? columnComponent
      : undefined;

  return (
    <div style={style ? style : { padding: ' 20px 15px' }}>
      {errorMsg && signalsToWatch.includes(error.id!) && (
        <Alert color='danger' style={{ marginTop: 15 }}>
          {errorMsg}
        </Alert>
      )}

      {notificationMessage && (
        <Alert color='info' style={{ marginTop: 15 }}>
          {notificationMessage}
        </Alert>
      )}

      <GenericTable
        columns={webEntity.tableColumns}
        tableData={preProcessedEntityList}
        fetchData={getEntities}
        addAction={renderAddButton()}
        addMultiLineAction={renderAddMultiLineButton()}
        deleteAction={renderDeleteButton}
        allowedActions={allowedTableActions}
        onSelectedRowsChange={selectedEntities}
        liveFilter={liveFilter}
        sharedFilterName={sharedFilterName}
        tableName={capitalize(title)}
        serverSidePagination={serverSidePagination}
        selectablePageOnly={allowedActionSelect.pageOnly}
        rowDisabledCriteria={allowedActionSelect.rowDisabledCriteria}
        rowColumnReference={webEntity.referenceColumn}
        secondaryAction={additionalTableActions}
        expandableRowsComponent={expandableRowsComponent}
        columnComponent={localColumnComponent}
        additionalTableProps={additionalTableProps}
        customExport={customExport}
        selectedSingleEntity={selectedSingleEntity}
        isAdmin={isAdmin}
        forceRefresh={forceRefresh}
        ViewInput={viewInputFilter}
        initialState={initialState}
        columnsHeaderStyle={columnsHeaderStyle}
        editAction={renderEditButton}
        enablePagination={enablePagination}
        enableColumnVirtualization={enableColumnVirtualization}
        enableRowVirtualization={enableRowVirtualization}
        optionsPagination={optionsPagination}
        enableCountError={enableCountError}
        webEntity={webEntity}
        loadingTable={loading}
        auth={auth}
        additionalTopTableActions={renderAdditionalTopTableActions()}
        customTopTable={customTopTable}
        enableStickyFooter={enableStickyFooter}
        bodyRowProps={bodyRowProps}
      />

      <ToastContainer theme='colored' />
    </div>
  );
};

export default GenericCRUD;
