import { BasicEntity } from '../types';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  EDIT_ENTITY,
  EntitiesActions,
  EntitiesState,
  GET_ENTITY,
  LOADING_ENTITY,
  SELECTED_ENTITY,
  SINGLE_SELECTED_ENTITY,
  NOTIFY_MESSAGE_ENTITY,
  REFRESH_ENTITY
} from './types';

const DefaultEntityState = {
  list: [],
  selected: [],
  singleSelected: null,
  loading: false,
  notificationMessage: ''
};

const initialState: EntitiesState = {
  user: DefaultEntityState,
  userType: DefaultEntityState,
  notification: DefaultEntityState,
  notificationType: DefaultEntityState,
  map: DefaultEntityState,
  costCenter: DefaultEntityState,
  zone: DefaultEntityState,
  zoneGlobal: DefaultEntityState,
  budget: DefaultEntityState,
  budgetType: DefaultEntityState,
  categories: DefaultEntityState,
  dictionaryCc: DefaultEntityState,
  areaManager: DefaultEntityState,
  subCostCenter: DefaultEntityState,
  expenses: DefaultEntityState,
  estimatedClosing: DefaultEntityState,
  pendingBudget: DefaultEntityState,
  pendingEstimatedClose: DefaultEntityState,
  globalEstimatedClose: DefaultEntityState,
  generalZoneReport: DefaultEntityState,
  generalReport: DefaultEntityState,
  provision: DefaultEntityState,
  reclassification: DefaultEntityState,
  refusedEstimatedClose: DefaultEntityState,
  hfm: DefaultEntityState,
  carrier: DefaultEntityState,
  managementType: DefaultEntityState,
  carrierType: DefaultEntityState,
  gloss: DefaultEntityState,
  zonePorting: DefaultEntityState,
  concept: DefaultEntityState,
  subConcept: DefaultEntityState,
  tariff: DefaultEntityState,
  volumen: DefaultEntityState,
  freightChargesQ1: DefaultEntityState,
  freightChargesQ2: DefaultEntityState,
  equivalentBox: DefaultEntityState,
  majorPorting: DefaultEntityState,
  contingencyWithholding: DefaultEntityState,
  contingencyWithholdingInconsistencies: DefaultEntityState,
  fareDifference: DefaultEntityState,
  fareDifferenceEntrepreneurs: DefaultEntityState,
  fareDifferenceSummary: DefaultEntityState,
  estimatedClosingPorting: DefaultEntityState,
  timeRoute: DefaultEntityState,
  ifac: DefaultEntityState,
  foreignExchange: DefaultEntityState,
  foreignExchangeEstimated: DefaultEntityState,
  foreignExchangeResume: DefaultEntityState,
  permanentTruckQ1: DefaultEntityState,
  permanentTruckQ2: DefaultEntityState,
  carrierZoneGloss: DefaultEntityState,
  freightGloss: DefaultEntityState,
  contractorTariff: DefaultEntityState,
  freightExtra: DefaultEntityState,
  freightExtraResume: DefaultEntityState,
  carrierRouteCarriage: DefaultEntityState,
  carrierTypeCarriage: DefaultEntityState,
  tariffCarriage: DefaultEntityState,
  freightProvision: DefaultEntityState,
  freightExtraGlSap: DefaultEntityState,
  freightExtraInconsistencies: DefaultEntityState,
  ifacSimulated: DefaultEntityState,
  freightTimeMaster: DefaultEntityState,
  extraCarriage: DefaultEntityState,
  reportUEN: DefaultEntityState,
  shipmentCarriageQ1: DefaultEntityState,
  shipmentCarriageQ2: DefaultEntityState,
  shipmentAuditingCarriageQ1: DefaultEntityState,
  shipmentAuditingCarriageQ2: DefaultEntityState,
  shipmentCarriagePXQQ1: DefaultEntityState,
  shipmentCarriagePXQQ2: DefaultEntityState,
  resumeShipmentCarriageQ1: DefaultEntityState,
  resumeShipmentCarriageQ2: DefaultEntityState,
  polynomial: DefaultEntityState,
  provisionCarriage: DefaultEntityState,
  budgetCarriageIngresoAcarreo: DefaultEntityState,
  budgetCarriageGastoRepro: DefaultEntityState,
  budgetCarriageGastoAcarreo: DefaultEntityState,
  budgetCarriageVolumenRepro: DefaultEntityState,
  carriageResume: DefaultEntityState,
  reporteGastos: DefaultEntityState
};

const entitiesReducer = (state = initialState, action: EntitiesActions): EntitiesState => {
  switch (action.type) {
    case GET_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], list: action.payload }
      };

    case SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], selected: action.payload }
      };

    case SINGLE_SELECTED_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], singleSelected: action.payload }
      };

    case DELETE_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: (state[action.entityName].list as BasicEntity[]).filter(
            (entity) => !action.payload.includes(entity._id)
          )
        }
      };

    case EDIT_ENTITY: //TODO: remove costCenterSap from here and from src/types (BasicEntity)
      const updatedList = state[action.entityName].list.map((e) =>
        e._id === action.payload._id ? action.payload : e
      );

      // Si no se actualizó ningún elemento por _id, intenta buscar y actualizar por costCenterSap
      if (updatedList.every((e) => e._id !== action.payload._id)) {
        const updatedListBySap = state[action.entityName].list.map((e) =>
          e.costCenterSap === action.payload.costCenterSap ? action.payload : e
        );

        return {
          ...state,
          [action.entityName]: {
            ...state[action.entityName],
            list: updatedListBySap
          }
        };
      }

      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: updatedList
        }
      };

    case ADD_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [
            ...action.payload,
            ...(state[action.entityName].list as BasicEntity[]).filter(
              (e) => !action.payload.some((p) => p._id === e._id)
            )
          ]
        }
      };

    case LOADING_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], loading: action.payload }
      };

    case NOTIFY_MESSAGE_ENTITY:
      return {
        ...state,
        [action.entityName]: { ...state[action.entityName], notificationMessage: action.payload }
      };

    case REFRESH_ENTITY:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          list: [...state[action.entityName].list]
        }
      };

    default:
      return state;
  }
};

export default entitiesReducer;
