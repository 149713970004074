import { Box } from '@mui/material';
import { FreightExtra } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const FreightExtraEntity: WebEntity<FreightExtra> = {
  name: 'freightExtra',
  endpoint: 'freightExtra',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Concepto',
      accessorKey: 'conceptName',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sub Concepto',
      accessorKey: 'subConceptDescription',
      columnType: 'text',
      size: 200
    },
    {
      header: 'EETT',
      accessorKey: 'carrierName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text',
      size: 200
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'SOLPED',
      accessorKey: 'purchaseRequest',
      columnType: 'text',
      size: 200
    },
    {
      header: 'OC',
      accessorKey: 'purchaseOrder',
      columnType: 'text',
      size: 200
    },
    {
      header: 'NP',
      accessorKey: 'noteOrder',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona CD',
      accessorKey: 'distributionCenterZona',
      columnType: 'text',
      size: 200
    },
    {
      header: 'CD',
      accessorKey: 'distributionCenter',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona',
      accessorKey: 'zonePortingDescription',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Monto Bruto',
      accessorKey: 'grossAmount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.grossAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.grossAmount, 0, 2)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.grossAmount ? acc + Number(obj.grossAmount) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Monto Neto',
      accessorKey: 'netAmount',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        row?.original?.netAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.netAmount, 0, 2)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.netAmount ? acc + Number(obj.netAmount) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Mes Pago',
      accessorKey: 'monthPayment',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) =>
        row.original?.monthPayment
          ? `${spanishMonthNames[new Date(row.original?.monthPayment).getUTCMonth()]} ${new Date(
              row.original?.monthPayment
            ).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Mes Cierre',
      accessorKey: 'monthClosing',
      columnType: 'dateUTC',
      size: 200,
      Cell: ({ row }) =>
        row.original?.monthClosing
          ? `${spanishMonthNames[new Date(row.original?.monthClosing).getUTCMonth()]} ${new Date(
              row.original?.monthClosing
            ).getUTCFullYear()}`
          : ''
    }
  ]
};

export const FREIGHT_EXTRA_COLUMNS = {
  bp: 'BP',
  carrierRut: 'RUT',
  carrierName: 'EETT',
  grossAmount: 'MONTO BRUTO',
  netAmount: 'MONTO NETO',
  purchaseRequest: 'SOLPED',
  purchaseOrder: 'OC',
  noteOrder: 'NP',
  conceptName: 'CONCEPTO',
  subConceptDescription: 'SUBCONCEPTO',
  monthPayment: 'MES_PAGO',
  monthClosing: 'MES_CIERRE',
  distributionCenterZona: 'ZONA_CD',
  distributionCenter: 'CD',
  zonePortingDescription: 'ZONA'
};

export default FreightExtraEntity;
