import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  useRef
} from 'react';
import { CSVLink } from 'react-csv';
import { Icon } from 'semantic-ui-react';
import { ColumnType, ExtendedColumnDef, WebEntity } from '../../entities/types';
import { BasicEntity, SimpleObject } from '../../types';
import { convertArrayOfObjectToFieldsMatrix, downloadXLSX } from '../../utils/export';
import { getObjectFromStorage, addObjectToStorage, removeFromStorage } from '../../utils/storage';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  BodyRowProps,
  ColumnComponent,
  CustomTopTable,
  InitialState,
  MRT_Row_WithOriginal,
  RowDisabledCriteria
} from '../types';
import {
  useMaterialReactTable,
  MRT_RowData,
  MaterialReactTable,
  MRT_TableInstance
} from 'material-react-table';
import { Box, Button, CircularProgress } from '@mui/material';
import { AuthState } from '../../reducers/types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CustomExport: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
  headerStyle: {
    backgroundColor: string;
    color: string;
  };
}> = ({ onExport, columnTypes, headerStyle }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes, undefined, headerStyle);

      setLoading(false);
    }, 500);
  };

  return (
    <Button onClick={handleExport} variant='contained' color='success' disabled={loading}>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

const StoredFormFilterManager: FunctionComponent<{
  storedFilterName: string;
  storedFormFilter: SimpleObject;
  refreshFunction: any;
}> = ({ storedFilterName, storedFormFilter, refreshFunction }) => {
  const objectToData = (simpleObject: SimpleObject) => ({
    header: ['filtro', 'valor'],
    rows: simpleObject
      ? Object.entries(simpleObject).filter(([_, v]) => v != null && v !== '')
      : [[]]
  });

  const data = objectToData(storedFormFilter);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(objectToData(storedFormFilter));
  }, [storedFormFilter]);

  const onCleanStorageSingleItem = (itemName: string) => {
    const data = getObjectFromStorage(storedFilterName);
    removeFromStorage(storedFilterName);

    delete data[itemName];
    addObjectToStorage(storedFilterName, data);
    refreshFunction();
  };

  return (
    <Fragment>
      {tableData.rows && tableData.rows[0]?.length > 0 && (
        <Fragment>
          {tableData.rows.map((item, i) => (
            <button
              key={i}
              style={{
                display: 'flex',
                height: '34px',
                padding: '2px 10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                border: '2px solid var(--ccu-verde-oscuro, #205C40)'
              }}
              onClick={() => onCleanStorageSingleItem(item[0])}>
              <p
                style={{
                  color: 'var(--ccu-verde-oscuro, #205C40)',
                  textAlign: 'center',
                  fontFamily: ' Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px'
                }}>
                {item[1]}
              </p>
              X
            </button>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

export const ExportCSV: FunctionComponent<{
  onExport: () => string[][];
}> = ({ onExport }) => (
  <CSVLink
    style={{
      display: 'inline-block',
      padding: '10px 20px',
      marginInline: '1em',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#4CAF50',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer'
    }}
    data={onExport()}
    filename={`export_${new Date().toISOString().split('T')[0]}.csv`}
    separator={';'}>
    <Icon name={'download'} />
  </CSVLink>
);

const Export: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
}> = ({ onExport, columnTypes }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes);

      setLoading(false);
    }, 500);
  };

  return (
    <Button onClick={handleExport} variant='contained' color='success' disabled={loading}>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

// Rows per page in server side
// TODO: change this once the endpoint let us set 'ROWS_PER_PAGE' from client side
const ROWS_PER_PAGE = 20;
const DEFAULT_PAGE = 1;

type Props<T> = {
  columns: ExtendedColumnDef<T>[];
  tableData: MRT_RowData[];
  fetchData: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  addAction?: JSX.Element;
  addMultiLineAction?: JSX.Element;
  deleteAction?: (
    getSelectedData: () => { ids: string[]; refs: string },
    table: MRT_TableInstance<MRT_RowData>
  ) => JSX.Element;
  secondaryAction?: AdditionalTableActionsFunc<T>;
  allowedActions?: Partial<{
    add: boolean;
    multiLineForm: boolean;
    delete: boolean;
    export: boolean;
    select: boolean;
    edit: boolean;
    editCondition?: (row: T) => boolean;
    singleSelect: boolean;
  }>;
  tableName?: string;
  serverSidePagination?: boolean;
  onSelectedRowsChange?: (rows: T[]) => void;
  liveFilter?: boolean;
  sharedFilterName?: string;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  selectablePageOnly?: boolean;
  rowDisabledCriteria?: RowDisabledCriteria<T>;
  rowColumnReference?: string;
  expandableRowsComponent?: () => ReactNode;
  columnComponent?: ColumnComponent<T>;
  editAction?: (row: BasicEntity) => JSX.Element;
  additionalTableProps?: AdditionalTableProps<T>;
  customExport?: {
    headerStyle: {
      backgroundColor: string;
      color: string;
    };
  };
  selectedSingleEntity?: (row: T, event: Event) => any;
  isAdmin?: boolean;
  forceRefresh?: boolean;
  ViewInput?: boolean;
  initialState?: InitialState;
  enablePagination?: boolean;
  enableColumnVirtualization?: boolean;
  enableRowVirtualization?: boolean;
  optionsPagination?: {
    initialStatePagination?: {
      pagination?: { pageSize: number; pageIndex: number };
    };
  };
  columnsHeaderStyle?: CSSProperties;
  enableCountError?: boolean;
  webEntity?: WebEntity<T>;
  loadingTable?: boolean;
  auth: AuthState;
  additionalTopTableActions?: JSX.Element;
  customTopTable?: CustomTopTable;
  enableStickyFooter?: boolean;
  bodyRowProps?: BodyRowProps;
};

const GenericTable = <T,>({
  // Required
  columns,
  tableData,
  fetchData,
  // Optionals
  fixedHeaderScrollHeight,
  fixedHeader = false,
  enablePagination = true,
  addAction,
  addMultiLineAction,
  deleteAction,
  editAction,
  secondaryAction,
  allowedActions,
  tableName = '',
  serverSidePagination = false,
  onSelectedRowsChange,
  liveFilter = false,
  sharedFilterName,
  selectablePageOnly = false,
  rowDisabledCriteria,
  rowColumnReference = '',
  expandableRowsComponent,
  columnComponent,
  additionalTableProps,
  customExport,
  selectedSingleEntity,
  columnsHeaderStyle,
  isAdmin,
  forceRefresh,
  ViewInput = true,
  initialState,
  enableColumnVirtualization = false,
  enableRowVirtualization = false,
  enableCountError = false,
  optionsPagination,
  webEntity,
  loadingTable,
  auth,
  additionalTopTableActions,
  customTopTable,
  enableStickyFooter = false,
  bodyRowProps
}: Props<T>) => {
  const [isloading, setLoading] = useState<boolean>(true);

  // Mount Flag
  const isMounted = useRef(true);

  const {
    add: canAdd,
    multiLineForm: canMultiLine,
    delete: canDelete,
    export: canExport,
    select: canSelect,
    edit: canEdit,
    editCondition
  } = {
    add: true,
    multiLineForm: true,
    delete: true,
    export: false,
    select: false,
    edit: true,
    ...allowedActions
  };

  const fetchDataPage = useMemo(
    () => async () => {
      setLoading(true);

      await fetchData();
      if (!isMounted.current) {
        return;
      }

      setLoading(false);
    },
    [fetchData]
  );

  useEffect(() => {
    fetchDataPage();
  }, [fetchDataPage]);

  useEffect(() => {
    let isTime: NodeJS.Timeout;
    if (!loadingTable) {
      isTime = setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    return () => {
      clearTimeout(isTime);
    };
  }, [loadingTable]);

  const renderActions = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {additionalTopTableActions || canMultiLine || canAdd ? (
        <div
          style={{
            display: 'flex',
            gap: ' 10px ',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%'
          }}>
          {additionalTopTableActions}
        </div>
      ) : null}
    </div>
  );

  const renderExport = useCallback(
    (data: SimpleObject[], columns: ExtendedColumnDef<T>[]) => {
      if (canExport) {
        if (customExport) {
          return (
            <CustomExport
              onExport={() =>
                convertArrayOfObjectToFieldsMatrix(
                  data,
                  columns.map((obj) => obj.accessorKey),
                  columns.map((obj) => obj.header)
                )
              }
              columnTypes={columns.map((obj) => obj.columnType)}
              headerStyle={customExport.headerStyle}
            />
          );
        }
        return (
          <Export
            onExport={() =>
              convertArrayOfObjectToFieldsMatrix(
                data,
                columns.map((obj) => obj.accessorKey),
                columns.map((obj) => obj.header)
              )
            }
            columnTypes={columns.map((obj) => obj.columnType)}
          />
        );
      }
      return <></>;
    },
    [canExport, customExport]
  );

  const column = useMemo(() => columns, [columns]);

  const renderTopToolbarCustomActions = useCallback(
    ({ table }: { table: MRT_TableInstance<MRT_RowData> }) => {
      const actions: Array<JSX.Element> = [];

      if (canMultiLine && addMultiLineAction) {
        actions.push(addMultiLineAction);
      }
      if (canAdd && addAction) {
        actions.push(addAction);
      }
      const visibleColumns = table.getVisibleFlatColumns().map((obj) => obj.id);
      const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
      const exportColumns = columns.filter(
        (obj) =>
          typeof obj.accessorKey === 'string' &&
          visibleColumns.includes(obj.accessorKey) &&
          !obj.omitExport
      );

      const data = rows.map((row) =>
        exportColumns.reduce((acc, obj) => {
          const key = obj.accessorKey as string;
          acc[key] = obj.valueToExport
            ? obj.valueToExport(row)
            : row.hasOwnProperty(key)
            ? row[key]
            : '';
          return acc;
        }, {} as Record<string, any>)
      );

      actions.push(renderExport(data, exportColumns));

      if (allowedActions?.delete && canDelete && deleteAction) {
        const getSelectedData = () => {
          const rowsToDelete = table.getSelectedRowModel().rows.map((row) => row.original);
          const ids = rowsToDelete.map((row) => row._id);
          const refs = `¿Seguro que deseas eliminar ${rowsToDelete.length} registros?`;

          return { ids, refs };
        };

        if (
          table.getIsSomeRowsSelected() ||
          table.getIsAllRowsSelected() ||
          table.getGroupedSelectedRowModel().rows.length > 0 ||
          table.getGroupedSelectedRowModel().flatRows.length > 0
        ) {
          actions.push(deleteAction(getSelectedData, table));
        }
      }
      if (secondaryAction) {
        actions.push(
          secondaryAction({
            selected: table.getSelectedRowModel().rows as MRT_Row_WithOriginal<T>[]
          })
        );
      }
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </Box>
      );
    },
    [allowedActions, canDelete, deleteAction, renderExport, secondaryAction]
  );

  const table = useMaterialReactTable({
    data: tableData,
    columns: column,
    enableGrouping: true,
    enablePagination: enablePagination,
    enableSelectAll: allowedActions?.delete || canSelect,
    layoutMode: 'grid',
    enableRowSelection: allowedActions?.delete || canSelect,
    positionToolbarAlertBanner: 'bottom',
    selectAllMode: 'all',
    enableStickyFooter,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Acciones',
        minSize: 80,
        maxSize: 100
      },
      'mrt-row-select': {
        minSize: 42,
        maxSize: 60
      },
      'mrt-row-expand': {
        header: 'Expandir',
        minSize: 80,
        maxSize: 100,
        enableResizing: true
      }
    },
    initialState: {
      grouping: initialState?.grouping,
      columnVisibility: initialState?.columnVisibility || {},
      columnPinning: {
        left: [
          'mrt-row-actions',
          'mrt-row-select',
          'mrt-row-expand',
          ...(initialState?.columnPinning?.left || [])
        ],
        right: initialState?.columnPinning?.right
      },
      pagination: {
        pageSize: optionsPagination?.initialStatePagination?.pagination?.pageSize || 100,
        pageIndex: optionsPagination?.initialStatePagination?.pagination?.pageIndex || 0
      },
      density: 'compact'
    },
    enableBottomToolbar: true,
    enableColumnResizing: true,
    enableGlobalFilterModes: true,
    enableColumnPinning: true,
    enableColumnVirtualization: enableColumnVirtualization,
    enableRowVirtualization: enableRowVirtualization,
    localization: MRT_Localization_ES,
    enableStickyHeader: true,
    enableColumnActions: true,
    enableExpandAll: false,
    enableColumnOrdering: true,
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    enableRowActions: canEdit,
    renderRowActions:
      canEdit && editAction
        ? ({ row }) => {
            if (editCondition && !editCondition(row?.original as T)) {
              return undefined;
            }
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                {editAction(row?.original as BasicEntity)}
              </Box>
            );
          }
        : undefined,
    renderTopToolbarCustomActions: (props) => renderTopToolbarCustomActions(props),
    getRowId: (row) => row._id,
    muiTableHeadCellProps: () => ({
      sx: columnsHeaderStyle
    }),
    muiTableBodyRowProps: bodyRowProps
  });

  return (
    <div style={{ display: 'flex ', flexDirection: 'column', gap: 12, position: 'relative' }}>
      {customTopTable
        ? customTopTable({
            auth,
            tableData,
            isloading
          })
        : tableName && <h2 className='title-DataTable'>{tableName.toLocaleUpperCase()}</h2>}
      {renderActions()}
      {isloading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}
    </div>
  );
};

export default GenericTable;
