import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { columns as reporteGastosColumns } from '../entities/ReporteGastosEntity';
import { EntitiesState } from '../reducers/types';
import { getUncatchEndpointData } from '../utils/request';
import { ReporteGastos as ReporteGastosType, SimpleObject } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from '../entities/types';
import { connect, ConnectedComponent } from 'react-redux';
import { ReporteGastosCRUD } from '../components/GeneratedComponents';
import BackButton from '../components/BackButton';
import { Button, Chip, Collapse, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { snakeToCamelCase } from '../utils/convert';
import { AppState } from '../store';
import * as E from '../entities';
import '../css/reporteGastos.css';

type SelectType = {
  value: string | number;
  show: string;
};

const GastosComponent: FunctionComponent<{
  entities: EntitiesState;
}> = ({ entities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [columnasList, setColumnasList] = useState<Array<SelectType>>([]);
  const [indicatorsList, setIndicatorsList] = useState<Array<SelectType>>([]);
  const [zonaList, setZonaList] = useState<Array<SelectType>>([]);
  const [categoriaList, setCategoriaList] = useState<Array<SelectType>>([]);
  const [descripCuentaList, setDescripCuentaList] = useState<Array<SelectType>>([]);
  const [mesList, setMesList] = useState<Array<SelectType>>([]);
  const [selectedColumnas, setSelectedColumnas] = useState<Array<SelectType>>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<Array<SelectType>>([]);
  const [selectedZona, setSelectedZona] = useState<Array<SelectType>>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<Array<SelectType>>([]);
  const [selectedDescripCuenta, setSelectedDescripCuenta] = useState<Array<SelectType>>([]);
  const [selectedMes, setSelectedMes] = useState<Array<SelectType>>([]);

  useEffect(() => {
    setLoading(true);
    const colums = reporteGastosColumns.map((obj) => ({
      value: obj.accessorKey as string,
      show: obj.header
    }));
    setColumnasList(colums);
    setSelectedColumnas(colums.slice(0, 3));

    (async () => {
      const indicators = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/indicadores'
      });
      if (indicators && indicators.length > 0) {
        const data: SelectType[] = indicators.map((row: SimpleObject) => ({
          value: row.columnName,
          show: row.indicador
        }));
        setIndicatorsList(data);
        const currentYear = new Date().getFullYear();
        const inititalIndicators = data.filter((d) => d.show.includes(currentYear.toString()));
        setSelectedIndicators(inititalIndicators);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/zona'
      });
      if (res && res.length > 0) {
        const data: SelectType[] = res.map((row: SimpleObject) => ({
          value: row.zoneId,
          show: row.zoneDescription
        }));
        setZonaList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/categoria'
      });
      if (res && res.length > 0) {
        const data: SelectType[] = res.map((row: SimpleObject) => ({
          value: row.categoriesId,
          show: row.categoriesName
        }));
        setCategoriaList(data);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/descripCuenta'
      });
      if (res && res.length > 0) {
        const data: SelectType[] = res.map((row: SimpleObject) => ({
          value: row.accountId,
          show: row.accountDescription
        }));
        setDescripCuentaList(data);
      }
    })();

    setMesList(spanishMonthNames.map((s, idx) => ({ value: idx, show: s })));
    //const currentMonth = new Date().getMonth();
    //setSelectedMes([{ value: currentMonth, show: spanishMonthNames[currentMonth] }]);
    setLoading(false);
  }, []);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns = reporteGastosColumns.filter((c) =>
      selectedColumnas.find((s) => s.value === c.accessorKey)
    );

    selectedIndicators.forEach((i) => {
      const key = i.value.toString();
      const accessorKey = snakeToCamelCase(key);
      tableColumns.push({
        header: i.show,
        accessorKey,
        columnType: 'numeric',
        accessorFn: (row) => formatStringNumber(row[accessorKey]),
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[accessorKey])}
          </div>
        )
      });
    });

    const entity: WebEntity<ReporteGastosType> = { ...E.ReporteGastosEntity, tableColumns };

    const columns = selectedColumnas.map((s) => s.value);
    const indicators = selectedIndicators.map((i) => i.value);
    const months = selectedMes.map((i) => i.value);

    return ReporteGastosCRUD(entity, { columns, indicators, months });
  }, [selectedColumnas, selectedIndicators, selectedMes]);

  const [checked, setChecked] = useState<boolean>(true);

  return (
    <div className='reporte-gastos-container'>
      <div>
        <BackButton route='/' />
      </div>

      <Collapse in={!checked}>
        <div className='reporte-gastos-collapse-filters-container'>
          <Button variant='text' onClick={() => setChecked(true)}>
            <ExpandMoreIcon />
            Expandir Filtros
          </Button>
        </div>
      </Collapse>
      <Collapse in={checked}>
        <div className='reporte-gastos-collapse-filters-container'>
          <div className='reporte-gastos-filters-container'>
            <div>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-column'>Columnas</InputLabel>
                <Select
                  disabled={loading}
                  value={selectedColumnas.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = columnasList.filter((item) =>
                      value.includes(item.value)
                    );
                    setSelectedColumnas(updatedSelected);
                  }}
                  label='Columnas'
                  labelId='select-label-column'
                  id='select-column'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedColumnas
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {columnasList.map((obj, idx) => (
                    <MenuItem value={obj.value || ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-indicador'>Indicadores</InputLabel>
                <Select
                  disabled={loading}
                  value={selectedIndicators.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = indicatorsList.filter((item) =>
                      value.includes(item.value)
                    );
                    setSelectedIndicators(updatedSelected);
                  }}
                  label='Indicadores'
                  labelId='select-label-indicador'
                  id='select-indicador'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedIndicators
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {indicatorsList.map((obj, idx) => (
                    <MenuItem value={obj.value || ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-zona'>Zona</InputLabel>
                <Select
                  disabled
                  value={selectedZona.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = zonaList.filter((item) => value.includes(item.value));
                    setSelectedZona(updatedSelected);
                  }}
                  label='Zona'
                  labelId='select-label-zona'
                  id='select-zona'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedZona
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {zonaList.map((obj, idx) => (
                    <MenuItem value={obj.value || ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-categoria'>Categoria</InputLabel>
                <Select
                  disabled
                  value={selectedCategoria.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = categoriaList.filter((item) =>
                      value.includes(item.value)
                    );
                    setSelectedCategoria(updatedSelected);
                  }}
                  label='Categoria'
                  labelId='select-label-categoria'
                  id='select-categoria'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedCategoria
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {categoriaList.map((obj, idx) => (
                    <MenuItem value={obj.value || ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-descrip-cuenta'>Descrip_Cuenta</InputLabel>
                <Select
                  disabled
                  value={selectedDescripCuenta.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = descripCuentaList.filter((item) =>
                      value.includes(item.value)
                    );
                    setSelectedDescripCuenta(updatedSelected);
                  }}
                  label='Descrip_Cuenta'
                  labelId='select-label-descrip-cuenta'
                  id='select-descrip-cuenta'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedDescripCuenta
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {descripCuentaList.map((obj, idx) => (
                    <MenuItem value={obj.value || ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%' }}>
                <InputLabel id='select-label-mes'>Mes</InputLabel>
                <Select
                  disabled
                  value={selectedMes.map((item) => item.value)}
                  multiple={true}
                  onChange={(e) => {
                    const value = e.target.value as Array<string | number>;
                    const updatedSelected = mesList.filter((item) => value.includes(item.value));
                    setSelectedMes(updatedSelected);
                  }}
                  label='Mes'
                  labelId='select-label-mes'
                  id='select-mes'
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {selectedMes
                        .filter((item) => selected.includes(item.value))
                        .map((s) => (
                          <Chip key={s.value} label={s.show} />
                        ))}
                    </div>
                  )}>
                  {mesList.map((obj, idx) => (
                    <MenuItem value={obj.value !== undefined ? obj.value : ''} key={idx}>
                      {obj.show}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Button variant='text' onClick={() => setChecked(false)}>
            <ExpandLessIcon />
            Ocultar
          </Button>
        </div>
      </Collapse>

      <CRUD />

      {/* <div className='reporte-gastos-chart-container'>
          <BarChart
            colors={['#4F81BD', '#C0504D']}
            xAxis={[{ scaleType: 'band', data: ['CD 8000', 'Centro Sur', 'Norte Chico'] }]}
            series={[
              { data: [-19450067, -30278746, -7384422] },
              { data: [-16626778, -26749141, -9411227] }
            ]}
            margin={{ left: 100 }}
          />
        </div> */}
    </div>
  );
};

export const ReporteGastos = connect(({ entities }: AppState) => ({
  entities
}))(GastosComponent);
