import { Box } from '@mui/material';
import { IfacSimulated } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const IfacSimulatedEntity: WebEntity<IfacSimulated> = {
  name: 'ifacSimulated',
  endpoint: 'ifac/simulated',
  referenceColumn: 'period',
  tableColumns: [
    {
      header: 'Tipo',
      accessorKey: 'type',
      columnType: 'text'
    },
    {
      header: 'Periodo',
      accessorKey: 'period',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'date',
      columnType: 'dateUTC',
      Cell: ({ row }) => dateFormatFn(row.original?.date, 'DD-MM-YYYY', true)
    },
    {
      header: 'A023',
      accessorKey: 'a023',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a023)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a023, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a023 ? acc + Number(obj.a023) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'A050',
      accessorKey: 'a050',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a050)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a050, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a050 ? acc + Number(obj.a050) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'A061',
      accessorKey: 'a061',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a061)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a061, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a061 ? acc + Number(obj.a061) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'A071',
      accessorKey: 'a071',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a071)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a071, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a071 ? acc + Number(obj.a071) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'A080',
      accessorKey: 'a080',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a080)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a080, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a080 ? acc + Number(obj.a080) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'A090',
      accessorKey: 'a090',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.a090)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.a090, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.a090 ? acc + Number(obj.a090) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      Cell: ({ row }) =>
        !isNaN(Number(row.original?.total)) ? (
          <Box sx={{ ...rightAlignStyle }}>{formatStringNumber(row.original?.total, 0, 2)}</Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, color: 'success.main', fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: IfacSimulated) => (obj.total ? acc + Number(obj.total) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15, color: 'green' }}>
            {formatStringNumber(total.toString(), 0, 2)}
          </Box>
        );
      }
    }
  ]
};

export default IfacSimulatedEntity;
