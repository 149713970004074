import { ReporteGastos } from '../types';
import { dateFormatFn, spanishMonthNames } from '../utils/utils';
import { ExtendedColumnDef, WebEntity } from './types';

const ReporteGastosEntity: WebEntity<ReporteGastos> = {
  name: 'reporteGastos',
  endpoint: 'reportes/gastos',
  referenceColumn: 'categoriesName',
  tableColumns: []
};

export default ReporteGastosEntity;

export const columns: ExtendedColumnDef<ReporteGastos>[] = [
  {
    header: 'Categoria',
    accessorKey: 'categoriesName',
    columnType: 'text'
  },
  {
    header: 'Zona',
    accessorKey: 'zoneDescription',
    columnType: 'text'
  },
  {
    header: 'CC',
    accessorKey: 'costCenterSap',
    columnType: 'text'
  },
  {
    header: 'CC_PS',
    accessorKey: 'codeCostCenter',
    columnType: 'text'
  },
  {
    header: 'Cta',
    accessorKey: 'accountSap',
    columnType: 'text'
  },
  {
    header: 'Descrip_Cuenta',
    accessorKey: 'accountDescription',
    columnType: 'text'
  },
  {
    header: 'Mes',
    accessorKey: 'monthDate',
    columnType: 'text',
    valueToExport: (row) => {
      const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
      if (isNaN(date.getTime())) {
        return '';
      }
      const month = date.getMonth();
      return spanishMonthNames[month];
    },
    accessorFn: (row) => {
      const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
      if (isNaN(date.getTime())) {
        return '';
      }
      const month = date.getMonth();
      return spanishMonthNames[month];
    }
  },
  {
    header: 'Nivel 1',
    accessorKey: 'nivelUno',
    columnType: 'text'
  },
  {
    header: 'Nivel 2',
    accessorKey: 'nivelDos',
    columnType: 'text'
  },
  {
    header: 'Nivel 3',
    accessorKey: 'nivelTres',
    columnType: 'text'
  },
  {
    header: 'Nivel 4',
    accessorKey: 'subCategoriesDescription',
    columnType: 'text'
  },
  {
    header: 'Nombre CC',
    accessorKey: 'costCenterName',
    columnType: 'text'
  }
];
