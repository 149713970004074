import 'bootstrap/dist/css/bootstrap.min.css';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import logo from '../img/ccu_logo.png';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser, logout } from '../actions/authActions';
import '../css/AppHome.css';
import Pages, { Maintainers, Reports, Inconsistencies } from '../pages';
import AppNavbar from './AppNavbar';
import { AppLink } from './types';
import GenericContainerSidebar from './generics/GenericContainerSidebar/GenericContainerSidebar';
import Logout from './auth/Logout';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import Login from './auth/Login';
import Swal from 'sweetalert2';

export const hiddenLinks: AppLink[] = [
  {
    href: '/',
    component: Pages.WelcomePage
  },
  {
    href: '/modulos',
    component: Pages.ModulesPage
  },
  {
    href: '/porteo',
    component: Pages.FreightPage
  },
  {
    href: '/acarreo',
    component: Pages.HaulingPage
  },
  {
    href: '/mapa-zona-global/:optionType',
    component: Pages.GlobalZoneMapPage
  },
  {
    href: '/mapa-zona-centro/:optionType/:globalZone/:level/:subCategories',
    component: Pages.CenterZoneMapPage
  },
  {
    href: '/operaciones/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Pages.OperationsPage
  }
];

const adminLinks: AppLink[] = [
  {
    href: '/administracion/usuario',
    component: Maintainers.UsuarioCRUDMaintainer
  },
  {
    href: '/administracion/mapa',
    component: Maintainers.MapCRUDMaintainer
  },
  {
    href: '/administracion/notificaciones',
    component: Maintainers.NotificationMaintainer
  },
  {
    href: '/mantenedores/SD/zonas/centro-de-costo',
    component: Maintainers.CostCenterCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/zonas/zona',
    component: Maintainers.ZoneCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/presupuesto/tipo-presupuesto',
    component: Maintainers.SDBudgetTypeCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/presupuesto/presupuesto-anual',
    component: Maintainers.SDBudgetCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/categorias',
    component: Maintainers.CategoriasCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/cuentas',
    component: Maintainers.DictionaryCcCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/zonas/zona-encargados',
    component: Maintainers.AreaManagerCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/zonas/zona-global',
    component: Maintainers.ZoneGlobalCRUDMaintainer
  },
  {
    href: '/mantenedores/SD/zonas/sub-cost-center',
    component: Maintainers.SubCostCentersCRUDMaintainer
  },
  {
    href: '/mantenedores/transportistas',
    component: Maintainers.CarrierCRUDMaintainer
  },
  {
    href: '/mantenedores/calendario',
    component: Maintainers.FreightTimeMasterCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/tipo-transportista',
    component: Maintainers.CarrierTypeCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/glosa',
    component: Maintainers.GlossCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/zona-porteo',
    component: Maintainers.ZonePortingCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/conceptos',
    component: Maintainers.ConceptCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/sub-conceptos',
    component: Maintainers.SubConceptCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/tarifas',
    component: Maintainers.TariffCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/presupuesto/tipo-presupuesto',
    component: Maintainers.PorteoBudgetTypeCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/presupuesto/presupuesto-anual',
    component: Maintainers.PorteoBudgetCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/gastos-porteo',
    component: Maintainers.FreightChargesCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/extras-porteo',
    component: Maintainers.FreightExtraCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/cajas-equivalentes',
    component: Maintainers.EquivalentBoxCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/mayor',
    component: Pages.MajorPortingPageConnected
  },
  {
    href: '/reportes/porteo/retencion-contingencia',
    component: Reports.ContingencyWithHoldingReportConnected
  },
  {
    href: '/mantenedores/diferencia-tarifa',
    component: Pages.FareDifferencePage
  },
  {
    href: '/reportes/porteo/estado-financiero',
    component: Reports.EstimatedClosingPortingReport
  },
  {
    href: '/mantenedores/acarreo/tiempo-de-ruta',
    component: Maintainers.TimeRouteCRUDMaintainer
  },
  {
    href: '/mantenedores/acarreo/ifac',
    component: Pages.IfacPageConnected
  },
  {
    href: '/mantenedores/acarreo/variables-macro',
    component: Pages.ForeignExchangePage
  },
  {
    href: '/mantenedores/porteo/camiones-fijos',
    component: Maintainers.PermanentTruckCRUDMaintainerConnected
  },
  {
    href: '/mantenedores/porteo/glosa-x-retencion',
    component: Maintainers.CarrierZoneGlossCRUDMaintainer
  },
  {
    href: '/mantenedores/porteo/cs-emprendedores',
    component: Maintainers.ContractorTariffCRUDMaintainerConnected
  },
  {
    href: '/reporte/inconsistencias/porteo/retencion-contingencia',
    component: Inconsistencies.ContingencyWithholdingInconsistenciesReport
  },
  {
    href: '/reporte/inconsistencias/porteo/extras',
    component: Inconsistencies.FreightExtraInconsistenciesReport
  },
  {
    href: '/mantenedores/acarreo/contrato-transportistas',
    component: Maintainers.CarrierRouteCarriageCRUDMaintainer
  },
  {
    href: '/mantenedores/acarreo/extras',
    component: Maintainers.ExtraCarriageCRUDMaintainerConnected
  },
  {
    href: '/reporte/acarreo/uen',
    component: Reports.ReportUENCRUDComponentConnected
  },
  {
    href: '/mantenedores/acarreo/flete',
    component: Pages.ShipmentCarriagePage
  },
  {
    href: '/reporte/acarreo/polinomio',
    component: Reports.PolynomialCRUDComponentConnected
  },
  {
    href: '/mantenedores/acarreo/resumen',
    component: Pages.CarriageResumePage
  }
];

export const allLinks: AppLink[] = [
  {
    href: '/reporte/totales-generales',
    component: Maintainers.GeneralReportMaintainer
  },
  {
    href: '/SD/gastos/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.ExpensesCRUDMaintainerConnected
  },
  {
    href: '/SD/HFM/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.HFMCRUDMaintainerConnected
  },
  {
    href: '/volumen',
    component: Maintainers.VolumenCRUDMaintainerConnected
  },
  {
    href: '/reporte/gastos',
    component: Reports.ReporteGastos
  },
  {
    href: '/estimado-cierre/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.EstimatedClosingCRUDMaintainer
  },
  {
    href: '/estimado-cierre-pendiente/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.EstimatedClosingPendingMaintainer
  },
  {
    href: '/estimado-cierre-rechazado/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.EstimatedClosingRejectedMaintainer
  },
  {
    href: '/estimado-cierre-global/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.EstimatedGlobalClosingMaintainer
  },
  {
    href: '/mantenedores/totales-generales/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.GeneralZoneReportMaintainer
  },
  {
    href: '/provisiones/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.ProvisionCRUDMaintainer
  },
  {
    href: '/provisiones-pendientes/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.PendingProvisionCRUDMaintainer
  },
  {
    href: '/provisiones-rechazadas/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.RefusedProvisionMaintainer
  },
  {
    href: '/provisiones-aprobadas/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.ApprovedProvisionMaintainer
  },
  {
    href: '/reclasificacion/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.ReclassificationCRUDMaintainer
  },
  {
    href: '/reclasificacion-pendientes/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.PendingReclassificationCRUDMaintainer
  },
  {
    href: '/reclasificacion-rechazadas/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.RefusedReclassificationCRUDMaintainer
  },
  {
    href: '/reclasificacion-aprobadas/:optionType/:globalZone/:level/:subCategories/:zones',
    component: Maintainers.ApprovedReclassificationCRUDMaintainer
  }
];

type Props = {
  auth: AuthState;
  loadUser: () => Promise<void>;
  logout: typeof logout;
};

const App: FunctionComponent<Props> = ({ auth, loadUser, logout }) => {
  const timeoutRef = useRef<number | undefined>(undefined);
  const time = useMemo(() => 1000 * 60 * 30, []); // total = 30 min

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      Swal.fire({
        title: '¡Tu sesión ha expirado!',
        icon: 'error'
      });
      logout();
    }, time);
  };

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    if (auth.token || auth.isAuthenticated) {
      const events = ['mousemove', 'keydown', 'click'];
      const handleActivity = () => {
        resetTimeout();
      };

      events.forEach((event) => window.addEventListener(event, handleActivity));
      resetTimeout();

      return () => {
        events.forEach((event) => window.removeEventListener(event, handleActivity));
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [auth]);

  const theme = useTheme();
  return (
    <div className='App'>
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>
      <ThemeProvider theme={createTheme(theme, esES)}>
        <Router>
          {auth.isAuthenticated ? (
            <GenericContainerSidebar
              img={logo}
              AppNavbar={<AppNavbar />}
              linkTopNav={'/'}
              logout={<Logout />}
              auth={auth}>
              <Switch>
                {[...hiddenLinks, ...adminLinks, ...allLinks].map(({ component, href }, k) => (
                  <Route key={`main_menu_${k}`} exact path={href} component={component} />
                ))}
                <Route path='/404' component={Pages.NotFoundPage} />
                <Redirect to='/404' />
              </Switch>
            </GenericContainerSidebar>
          ) : (
            <Login />
          )}
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser, logout })(App);
