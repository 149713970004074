import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { Button } from '@mui/material';
import { AuthState } from '../reducers/types';
import { useHistory } from 'react-router-dom';
import { Path } from 'history';
import { UserTypeValues } from '../types';
import BarChartIcon from '@mui/icons-material/BarChart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import '../css/welcomePage.css';

type Props = {
  auth: AuthState;
};

const WelcomePage: FunctionComponent<Props> = ({ auth }) => {
  const router = useHistory();

  const buttons: Array<{
    name: string;
    icon: JSX.Element;
    disabled: boolean;
    onlyAdmin: boolean;
    href: Path;
  }> = [
    {
      name: 'Volumen',
      icon: <AddRoadIcon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: false,
      href: '/volumen'
    },
    {
      name: 'Gastos',
      icon: <CurrencyExchangeIcon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: false,
      href: '/modulos'
    },
    {
      name: 'Reporteria Gastos',
      icon: <BarChartIcon sx={{ fontSize: 80 }} />,
      disabled: false,
      onlyAdmin: false,
      href: '/reporte/gastos'
    }
  ];

  return (
    <div className='welcome-page-container'>
      {buttons.map((b, idx) => {
        if (b.onlyAdmin && !auth.user?.userTypeName.includes(UserTypeValues.admin)) {
          return null;
        }
        return (
          <div className='welcome-button-container' key={idx}>
            <Button
              className={`welcome-button ${b.disabled ? 'welcome-button-disabled' : ''}`}
              disabled={b.disabled}
              onClick={!b.disabled ? () => router.push(b.href) : undefined}>
              {b.icon}
              <p>{b.name}</p>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(WelcomePage);
