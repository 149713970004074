import { Box } from '@mui/material';
import { EstimatedClosingPorting } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const EstimatedClosingPortingEntity: WebEntity<EstimatedClosingPorting> = {
  name: 'fareDifference',
  endpoint: 'estimatedClosingPorting',
  referenceColumn: '_id',

  tableColumns: [
    {
      header: 'Porteo',
      accessorKey: 'typeInfo',
      columnType: 'text',
      Cell: ({ row }) => <Box sx={{ fontWeight: 'bold' }}>{row?.original?.typeInfo}</Box>
    },
    {
      header: 'Tipo',
      accessorKey: 'carrierTypeName',
      columnType: 'text',
      Footer: () => (
        <Box>
          Total Gastos
          <ArrowRightAltIcon />
        </Box>
      )
    },
    {
      header: 'Enero',
      accessorKey: 'january',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.january ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.january) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.january, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.january);
          const currentYearTariff = Number(row.subRows[1].original.january);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }

        const sum = cell.getValue();
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.january ? acc + Number(obj.january) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Febrero',
      accessorKey: 'february',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.february ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.february) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.february, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.february);
          const currentYearTariff = Number(row.subRows[1].original.february);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.february ? acc + Number(obj.february) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Marzo',
      accessorKey: 'march',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.march ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.march) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.march, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.march);
          const currentYearTariff = Number(row.subRows[1].original.march);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.march ? acc + Number(obj.march) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Abril',
      accessorKey: 'april',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.april ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.april) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.april, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.april);
          const currentYearTariff = Number(row.subRows[1].original.april);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.april ? acc + Number(obj.april) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Mayo',
      accessorKey: 'may',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.may ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.may) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.may, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.may);
          const currentYearTariff = Number(row.subRows[1].original.may);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.may ? acc + Number(obj.may) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Junio',
      accessorKey: 'june',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.june ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.june) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.june, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.june);
          const currentYearTariff = Number(row.subRows[1].original.june);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.june ? acc + Number(obj.june) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Julio',
      accessorKey: 'july',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.july ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.july) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.july, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.july);
          const currentYearTariff = Number(row.subRows[1].original.july);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.july ? acc + Number(obj.july) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Agosto',
      accessorKey: 'august',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.august ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.august) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.august, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.august);
          const currentYearTariff = Number(row.subRows[1].original.august);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.august ? acc + Number(obj.august) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Septiembre',
      accessorKey: 'september',
      columnType: 'numeric',
      size: 170,
      Cell: ({ row }) =>
        row?.original?.september ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.september) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.september, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.september);
          const currentYearTariff = Number(row.subRows[1].original.september);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.september ? acc + Number(obj.september) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Octubre',
      accessorKey: 'october',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.october ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.october) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.october, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.october);
          const currentYearTariff = Number(row.subRows[1].original.october);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.october ? acc + Number(obj.october) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Noviembre',
      accessorKey: 'november',
      columnType: 'numeric',
      size: 170,
      Cell: ({ row }) =>
        row?.original?.november ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.november) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.november, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.november);
          const currentYearTariff = Number(row.subRows[1].original.november);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.november ? acc + Number(obj.november) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Diciembre',
      accessorKey: 'december',
      columnType: 'numeric',
      size: 160,
      Cell: ({ row }) =>
        row?.original?.december ? (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${Number(row?.original?.december) >= 0 ? '#000' : 'red'}`
            }}>
            {formatStringNumber(row?.original?.december, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell, row }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          if (row.subRows?.length !== 2) {
            return '';
          }
          const lastYearTariff = Number(row.subRows[0].original.december);
          const currentYearTariff = Number(row.subRows[1].original.december);
          const value =
            currentYearTariff === 0
              ? 0
              : ((lastYearTariff - currentYearTariff) / currentYearTariff) * 100;
          return (
            <Box
              sx={{
                ...rightAlignStyle,
                fontWeight: 'bold'
              }}>
              {formatStringNumber(value.toString(), 0, 2)}%
            </Box>
          );
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.december ? acc + Number(obj.december) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Total',
      accessorKey: 'total',
      columnType: 'numeric',
      size: 140,
      Cell: ({ row }) =>
        row?.original?.total ? (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${Number(row?.original?.total) >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(row?.original?.total, 0, 0)}
          </Box>
        ) : (
          <Box
            sx={{
              ...rightAlignStyle,
              color: '#000'
            }}>
            0
          </Box>
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        if ((cell.row.groupingValue as string).includes('% Tarifa')) {
          return '';
        }
        const sum = cell.getValue();

        return (
          <Box
            sx={{
              ...rightAlignStyle,
              color: `${!isNaN(Number(sum)) && Number(sum) >= 0 ? 'success.main' : 'red'}`,
              fontWeight: 'bold'
            }}>
            {sum ? `$ ${formatStringNumber(sum?.toString(), 0, 0)}` : ''}
          </Box>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce((acc: number, obj: EstimatedClosingPorting) => {
          if (!obj.typeInfo.includes('% Tarifa')) {
            return obj.total ? acc + Number(obj.total) : acc;
          }
          return acc;
        }, 0);
        return (
          <Box
            sx={{
              ...rightAlignStyle,
              fontWeight: 'bold',
              color: `${total >= 0 ? '#000' : 'red'}`
            }}>
            $ {formatStringNumber(total.toString(), 0, 0)}
          </Box>
        );
      }
    }
  ]
};

export default EstimatedClosingPortingEntity;
