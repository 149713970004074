import * as Papa from 'papaparse';
import { FunctionComponent, useState } from 'react';
import { errorPopAlert, popAlert, PopAlertType, requestErrorPopAlert } from './PopAlert';
import GenericHeaderExportButton from '../components/generics/GenericHeaderExportButton';
import { SimpleObject, SimpleStringObject } from '../types';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import '../css/fileReader.css';
import { FileUploader } from 'react-drag-drop-files';

type FileReaderColumnObject = {
  name: string;
  required?: boolean;
  upload?: boolean; // NOTE:Even if not required, the queue can still be uploaded.
  export?: boolean;
  format?: (field: any) => string | boolean | number | Date;
};
export type FileReaderColumnProps = { [key: string]: string | FileReaderColumnObject };
const isNullOrUndefined = (value: any) => value === null || value === undefined;

type Props = {
  columns?: FileReaderColumnProps;
  callback: (data: Array<{ [key: string]: string }>) => Promise<any>;
  dataInputName?: string; // NOTE: the name to show to the user in case of error
  disabled?: boolean;
};

const FileReaderCSV: FunctionComponent<Props> = ({
  columns = {},
  callback,
  dataInputName,
  disabled
}) => {
  const requiredColumns = Object.keys(columns).filter(
    (key) =>
      typeof columns[key] === 'string' ||
      (typeof columns[key] === 'object' &&
        (columns[key] as FileReaderColumnObject)?.required === true)
  );

  const requiredColumnsForUpload = Object.keys(columns).filter(
    (key) =>
      typeof columns[key] === 'string' ||
      (typeof columns[key] === 'object' &&
        ((columns[key] as FileReaderColumnObject)?.required === true ||
          (columns[key] as FileReaderColumnObject)?.upload === true))
  );

  const desiredColumns = Object.keys(columns).filter(
    (key) =>
      typeof columns[key] === 'object' &&
      (columns[key] as FileReaderColumnObject)?.required === false
  );

  const exportColumns = [];

  for (const key in columns) {
    const isRequired = (columns[key] as FileReaderColumnObject)?.required !== false;
    const isExport =
      // IF is explicitly true
      (columns[key] as FileReaderColumnObject)?.export === true ||
      // OR in CASE to be undefined or null, is export only if `isRequired` is also true
      (isNullOrUndefined((columns[key] as FileReaderColumnObject)?.export) && isRequired);

    if (typeof columns[key] === 'string') {
      exportColumns.push(columns[key]);
    } else if (typeof columns[key] === 'object' && isExport) {
      exportColumns.push((columns[key] as any).name);
    }
  }

  const columnMap: SimpleStringObject = {};
  const headerTemplatecolumnMap: SimpleStringObject = {};

  for (const key in columns) {
    if (typeof columns[key] === 'object' && (columns[key] as any).name) {
      columnMap[(columns[key] as FileReaderColumnObject).name] = key;
      headerTemplatecolumnMap[key] = (columns[key] as FileReaderColumnObject).name;
    } else {
      columnMap[columns[key] as string] = key;
      headerTemplatecolumnMap[key] = columns[key] as string;
    }
  }

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleChange = (file: File) => {
    setCsvFile(file);
    setFileName(file.name);
  };

  const headerTemplate = [...exportColumns].map(
    (header) => (headerTemplatecolumnMap as any)[header] || header
  );

  const columnsFormat: SimpleObject = Object.keys(columns).reduce((acc, currentKey) => {
    if (
      typeof columns[currentKey] === 'object' &&
      (columns[currentKey] as FileReaderColumnObject).format
    ) {
      acc[currentKey] = (columns[currentKey] as FileReaderColumnObject).format;
    }
    return acc;
  }, {} as SimpleObject);

  const processedData: Array<any> = []; // Almacenará los datos procesados
  const importCSV = () => {
    try {
      setLoading(true);
      Papa.parse(csvFile!, {
        complete: () => {
          updateData(processedData);
        }, // NOTE: when step is used, we have to manage the data by ourselves
        header: true,
        delimiter: ';',
        step: (row: any) => {
          try {
            // NOTE: Checking the last line that always come with only one column and without data
            if (Object.keys(row.data).length === 1 && !row.data[Object.keys(row.data)[0]]) {
              return;
            }

            // This function apply the 'format' function to the specific field of the row
            if (Object.keys(columnsFormat).length > 0) {
              Object.keys(columnsFormat).forEach((rowDataKey) => {
                row.data[rowDataKey] = columnsFormat[rowDataKey](row.data[rowDataKey]);
              });
            }
            processedData.push(row.data);
          } catch (error: any) {
            // NOTE: Only catch to show the error
            errorPopAlert(
              'Ha ocurrido un error mientras de intentaba cargar el archivo',
              error.message
            );
            setLoading(false);
            // NOTE: Throw error anyway to avoid the file upload
            throw new Error(`'Ha ocurrido un error mientras de intentaba cargar el archivo`);
          }
        },
        transformHeader: (header: string) => {
          // NOTE: trimming Header
          const headerTrim = header.trim();
          return (columnMap as any)[headerTrim] || headerTrim;
        }
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      requestErrorPopAlert(error);
    }
  };

  // NOTE: By using 'step' callback you should manage the data by yourself. otherwhise the 'complete' callback will gives you the 'result: Papa.ParseResult<any>'
  //const updateData = async (result: Papa.ParseResult<any>) => {
  const updateData = async (data: Array<any>) => {
    try {
      setLoading(true);

      if (data.length < 1) {
        throw new Error('El archivo recibido no tiene filas con registros');
      }

      const uniqueFn = (c: string, idx: number, self: Array<string>) => self.indexOf(c) === idx;

      const classifiedRows = data.reduce(
        (acc: any, row: SimpleObject, idx: any) => {
          const notFoundRequiredColumns: any = requiredColumns.find(
            (c: any) => typeof row[c] !== 'boolean' && !row[c]
          );
          const notFoundDesiredColumns = desiredColumns.find(
            (c: any) => typeof row[c] !== 'boolean' && !row[c]
          );
          !notFoundRequiredColumns &&
            acc.valids.push(
              // filter only the 'requiredColumnsForUpload' of each 'row'
              requiredColumnsForUpload.reduce((obj, key: string) => {
                if (key in row) {
                  obj[key] = row[key];
                }
                return obj;
              }, {} as { [key: string]: any })
            );
          notFoundRequiredColumns && acc.invalids.push(row);
          notFoundDesiredColumns && acc.warnings.push(row);
          notFoundRequiredColumns && acc.notFoundRequiredColumns.push(notFoundRequiredColumns);
          notFoundDesiredColumns && acc.notFoundDesiredColumns.push(notFoundDesiredColumns);
          notFoundRequiredColumns && acc.notFoundRequiredOnRows.push(idx + 2); // +2 beacuse idx start from 0 (then +1) plus the header row

          return acc;
        },
        {
          valids: [],
          invalids: [],
          warnings: [],
          notFoundRequiredColumns: [],
          notFoundDesiredColumns: [],
          notFoundRequiredOnRows: []
        }
      );

      classifiedRows.notFoundRequiredColumns =
        classifiedRows.notFoundRequiredColumns.filter(uniqueFn);
      classifiedRows.notFoundDesiredColumns =
        classifiedRows.notFoundDesiredColumns.filter(uniqueFn);

      const columnNames: { [key: string]: string } = Object.keys(columns).reduce((acc, key) => {
        if (typeof columns[key] === 'object' && (columns[key] as FileReaderColumnObject)) {
          return { ...acc, ...{ [key]: (columns[key] as any).name } };
        }
        return { ...acc, ...{ [key]: columns[key] } };
      }, {});

      if (classifiedRows.invalids.length > 0) {
        const invalidRowText = Object.keys(classifiedRows.invalids[0]).reduce(
          (acc: string[], key) => {
            acc.push(
              `${columnNames[key] ? columnNames[key] : `CABECERA NO VALIDA -> (${key})`}: ${
                classifiedRows.invalids[0][key] ? `"${classifiedRows.invalids[0][key]}"` : `[vacío]`
              }`
            );
            return acc;
          },
          []
        );

        const invalidRowTextString = invalidRowText.join('\n');
        const notFoundRequiredColumns = classifiedRows.notFoundRequiredColumns.map(
          (c: string) => `"${columnNames[c]}"`
        );

        popAlert({
          type: PopAlertType.ERROR,
          title: `${
            dataInputName ? `Asegúrese de que esta cargando un ${dataInputName}.\n` : ''
          }El archivo no posee todas las columnas requeridas, revise e intente nuevamente por favor.`,
          details: `No se encontraron las columnas: ${notFoundRequiredColumns.join(
            ', '
          )} en las filas: ${classifiedRows.notFoundRequiredOnRows.join(', ')}.

Detalle de la primera fila con error:
\n ${invalidRowTextString}`
        });

        setLoading(false);
        setCsvFile(null);
        return;
      }

      setLoading(false);
      setUploading(true);
      toast.promise(
        async () => {
          await callback(classifiedRows.valids);
        },
        {
          pending: 'Cargando...',
          error: 'Error al tratar de cargar el archivo',
          success: 'Se completo de manera exitosa'
        }
      );
      setUploading(false);

      // Reset values to null
      setCsvFile(null);
      setFileName('');
    } catch (error: any) {
      console.log(error);
      setUploading(false);
      setLoading(false);

      errorPopAlert(
        'Ha ocurrido un error mientras de intentaba cargar el archivo',
        '',
        error.message
      );

      // Reset values to null
      setCsvFile(null);
      setFileName('');
    }
  };

  return (
    <div className='file-reader-container'>
      <div className='file-reader-input-container'>
        <FileUploader
          classes='file-reader-input'
          handleChange={handleChange}
          name='file'
          label='Cargue o suelte un archivo CSV aquí'
          hoverTitle='Soltar aquí'
          types={['csv']}
          multiple={false}
          required={true}
          fileOrFiles={csvFile}
        />
        {fileName && (
          <p className='file-reader-file-name'>
            Archivo: <span>{fileName}</span>
          </p>
        )}
      </div>

      <div className='file-reader-buttons-container'>
        <Button
          disabled={!csvFile || loading || uploading || disabled}
          variant='contained'
          color='success'
          onClick={importCSV}>
          <FileUploadIcon />
          Procesar Archivo
        </Button>
        <GenericHeaderExportButton headers={headerTemplate} />
      </div>
    </div>
  );
};

export default FileReaderCSV;
