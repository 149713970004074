import { PendingEstimatedClose, StatusBudgetNames } from '../types';
import { formatStringNumber, specialFormatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';
import {
  Button,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { useMemo, useState } from 'react';
import { EditStatusEstimatedClosed } from '../components/SecondaryButton';

const EstimatedClosingPendingEntity: WebEntity<PendingEstimatedClose> = {
  name: 'pendingBudget',
  endpoint: 'expenses/estimatedClose/pendingEstimatedClose',
  referenceColumn: 'pendingBudget',
  tableColumns: [
    {
      header: 'Opciones',
      accessorKey: '_id',
      id: 'options',
      omitExport: true,
      columnType: 'text',
      size: 400,
      Cell: ({ row }) => {
        const [option, setOption] = useState<string>('');
        const dataOptions = useMemo(
          () => [StatusBudgetNames.approved, StatusBudgetNames.rejected],
          []
        );

        const [textExplication, setTextExplication] = useState('');

        return (
          <div
            style={{
              display: 'flex',
              gap: '10px'
            }}>
            <FormControl variant='filled' sx={{ minWidth: 140 }}>
              <InputLabel id='estimated-closing-select-label'>Estado Cierre</InputLabel>
              <Select
                labelId='estimated-closing-select-label'
                id='estimated-closing-select'
                onChange={(e: SelectChangeEvent) => setOption(e.target.value)}
                value={option}
                style={{
                  border: `1px solid ${
                    option === StatusBudgetNames.approved
                      ? 'green'
                      : option === StatusBudgetNames.rejected
                      ? 'red'
                      : 'transparent'
                  }`
                }}
                label='Opción'>
                {dataOptions.map((opt, idx) => (
                  <MenuItem key={idx} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {option === StatusBudgetNames.rejected && (
              <TextField
                variant='outlined'
                label='Comentario'
                onChange={(e) => setTextExplication(e.target.value || '')}
              />
            )}
            {option === StatusBudgetNames.approved ? (
              <EditStatusEstimatedClosed option={option} estimatedCloseId={row.original._id} />
            ) : option === StatusBudgetNames.rejected && textExplication ? (
              <EditStatusEstimatedClosed
                option={option}
                estimatedCloseId={row.original._id}
                textExplication={textExplication}
              />
            ) : null}
          </div>
        );
      }
    },
    {
      header: 'Texto Explicativo',
      accessorKey: 'estimatedDescription',
      id: 'estimatedDescription',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Comentario Justificacion',
      accessorKey: 'justificationCommentary',
      id: 'justificationCommentary',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Archivo PDF o Imagen',
      accessorKey: 'imageUrl',
      id: 'imageUrl',
      omitExport: true,
      columnType: 'text',
      size: 250,
      Cell: ({ row }) => {
        const handleDownload = () => {
          const downloadLink = document.createElement('a');
          downloadLink.href = `data:${row?.original?.fileType ?? 'pdf'};base64,${
            row?.original?.imageUrl
          }`;
          downloadLink.download = `${row?.original?.fileName}.${row?.original?.fileType}`;
          downloadLink.click();
        };
        return (
          row?.original?.imageUrl && (
            <Button variant='outlined' onClick={handleDownload}>
              Descargar
            </Button>
          )
        );
      }
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      id: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Cta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Cta PS',
      accessorKey: 'ctaPs',
      id: 'ctaPs',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Descripcion Categoria',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Presupuesto',
      accessorKey: 'presupuestoAnualTotal',
      id: 'presupuestoAnualTotal',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => formatStringNumber(row?.original?.presupuestoAnualTotal)
    },
    {
      header: 'Estimado Cierre',
      accessorKey: 'estimatedTotal',
      id: 'estimatedTotal',
      columnType: 'numeric',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row?.original?.estimatedTotal)
    },
    {
      header: 'Diferencia',
      accessorKey: 'diferencia',
      id: 'diferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => `${formatStringNumber(row?.original?.diferencia)}`
    },
    {
      header: 'Porcentaje',
      accessorKey: 'porcentajeDiferencia',
      id: 'porcentajeDiferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        `${specialFormatStringNumber(
          (Number(row?.original?.porcentajeDiferencia) * 100).toFixed(2),
          true,
          2
        )}%`
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export const PENDINGS_ESTIMATED_CLOSED_COLUMNS = {
  categorieName: 'Categoria',
  accountSap: 'Cta',
  ctaPs: 'Cta PS',
  accountDescription: 'Descipcion Cuenta',
  estimatedTotal: 'Estimado Cierre'
};

export default EstimatedClosingPendingEntity;
