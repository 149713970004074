import { EstimatedClosing } from '../types';
import { formatStringNumber, specialFormatStringNumber } from '../utils/utils';
import { WebEntity } from './types';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { EstimatedClosingAction } from '../components/SecondaryButton';
import { MRT_RowData } from 'material-react-table';

const EstimatedClosingEntity: WebEntity<EstimatedClosing> = {
  name: 'estimatedClosing',
  endpoint: 'expenses/estimatedClosing',
  referenceColumn: 'pendingBudget',
  tableColumns: [
    {
      header: 'Texto Explicativo',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      id: 'options',
      size: 540,
      Cell: ({ row }) => {
        return <EstimatedClosingAction row={row.original as EstimatedClosing} />;
      },
      AggregatedCell: ({ row }) => {
        const [rows, setRows] = useState<Array<MRT_RowData>>([]);
        const [offBudget, setOffBudget] = useState<boolean>(false);

        useEffect(() => {
          if (row.subRows) {
            setRows(row.subRows?.map((obj) => obj.original));
          }
        }, [row.subRows]);

        useEffect(() => {
          const isOffBudget = rows.some((obj) => {
            return (
              (!isNaN(Number(obj?.presupuestoAnualTotal)) &&
                !isNaN(Number(obj?.estimatedTotal)) &&
                Number(obj.presupuestoAnualTotal) === 0 &&
                Number(obj.estimatedTotal) !== 0) ||
              obj.porcentajeDiferencia * 100 < -15 ||
              obj.porcentajeDiferencia * 100 > 10
            );
          });

          setOffBudget(isOffBudget);
        }, [rows]);

        return offBudget && <Alert severity='error'>Fuera del presupuesto</Alert>;
      }
    },
    {
      header: 'ID',
      accessorKey: '_id',
      id: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      id: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      id: 'zoneDescrp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Nivel 4',
      accessorKey: 'description',
      id: 'description',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Cta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Cta PS',
      accessorKey: 'ctaPs',
      id: 'ctaPs',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Descripcion Categoria',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Presupuesto',
      accessorKey: 'presupuestoAnualTotal',
      id: 'presupuestoAnualTotal',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => formatStringNumber(row?.original?.presupuestoAnualTotal)
    },
    {
      header: 'Estimado Cierre',
      accessorKey: 'estimatedTotal',
      id: 'estimatedTotal',
      columnType: 'text',
      size: 230,
      Cell: ({ row }) => formatStringNumber(row?.original?.estimatedTotal)
    },
    {
      header: 'Diferencia',
      accessorKey: 'diferencia',
      id: 'diferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => `${formatStringNumber(row?.original?.diferencia)}`
    },
    {
      header: 'Porcentaje',
      accessorKey: 'porcentajeDiferencia',
      id: 'porcentajeDiferencia',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        `${specialFormatStringNumber(
          (Number(row?.original?.porcentajeDiferencia) * 100).toFixed(2),
          true,
          2
        )}%`
    }
  ]
};

export const ESTIMATED_CLOSING_COLUMNS = {
  costCenterSap: 'CC SAP',
  categoriesName: 'Categoria',
  accountSap: 'Cta',
  ctaPs: 'Cta PS',
  accountDescription: 'Descipcion Cuenta',
  estimatedTotal: 'Estimado Cierre'
};

export default EstimatedClosingEntity;
