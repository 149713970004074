import { Box } from '@mui/material';
import { FileReaderColumnProps } from '../components/FileReader';
import { ExtraCarriage } from '../types';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { WebEntity } from './types';

const rightAlignStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
};

const ExtraCarriageEntity: WebEntity<ExtraCarriage> = {
  name: 'extraCarriage',
  endpoint: 'extraCarriage',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'Categoria',
      accessorKey: 'category',
      columnType: 'text'
    },
    {
      header: 'Fecha',
      accessorKey: 'extraCarriageDate',
      columnType: 'dateUTC',
      Cell: ({ row }) =>
        row.original?.extraCarriageDate
          ? `${
              spanishMonthNames[new Date(row.original?.extraCarriageDate).getUTCMonth()]
            } ${new Date(row.original?.extraCarriageDate).getUTCFullYear()}`
          : ''
    },
    {
      header: 'Rut',
      accessorKey: 'carrierRut',
      columnType: 'text'
    },
    {
      header: 'Razon Social',
      accessorKey: 'carrierName',
      columnType: 'text'
    },
    {
      header: 'Neto',
      accessorKey: 'netAmount',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.netAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.netAmount, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 0) : ''}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.netAmount ? acc + Number(obj.netAmount) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Bruto',
      accessorKey: 'grossAmount',
      columnType: 'numeric',
      Cell: ({ row }) =>
        row?.original?.grossAmount ? (
          <Box sx={{ ...rightAlignStyle }}>
            {formatStringNumber(row?.original?.grossAmount, 0, 0)}
          </Box>
        ) : (
          ''
        ),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ ...rightAlignStyle, fontWeight: 'bold' }}>
            {sum ? formatStringNumber(sum?.toString(), 0, 0) : ''}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce(
          (acc, obj) => (obj.grossAmount ? acc + Number(obj.grossAmount) : acc),
          0
        );
        return (
          <Box sx={{ ...rightAlignStyle, fontSize: 15 }}>
            {formatStringNumber(totalFooter.toString(), 0, 0)}
          </Box>
        );
      }
    },
    {
      header: 'Glosa',
      accessorKey: 'gloss',
      columnType: 'text'
    },
    {
      header: 'Solped',
      accessorKey: 'purchaseRequest',
      columnType: 'text'
    },
    {
      header: 'OC',
      accessorKey: 'purchaseOrder',
      columnType: 'text'
    },
    {
      header: 'NP',
      accessorKey: 'noteOrder',
      columnType: 'text'
    },
    {
      header: 'Comentario',
      accessorKey: 'comment',
      columnType: 'text'
    },
    {
      header: 'Factura',
      accessorKey: 'invoice',
      columnType: 'text'
    }
  ]
};

export const EXTRA_CARRIAGE_COLUMNS: FileReaderColumnProps = {
  bp: 'BP',
  carrierRut: 'RUT',
  carrierName: 'Razon Social',
  netAmount: 'Neto',
  grossAmount: 'Bruto',
  gloss: 'GLOSA',
  purchaseRequest: 'Solped',
  purchaseOrder: 'OC',
  noteOrder: 'NP',
  comment: { name: 'COMENTARIO', required: false, export: true, upload: true },
  invoice: { name: 'Factura', required: false, export: true, upload: true },
  category: 'CATEGORIA'
};

export default ExtraCarriageEntity;
